export const CATEGORY_FRAGMENT = `
  fragment category on Category {
    id
    name
    description
    slug
    backgroundImage {
      url
      alt
    }
    translation(languageCode: $language) {
      id
      name
      description
    }
  }
`;
