import { Client } from "../Client";
import { TaxedMoneyRange } from "../Types/Common";
import { Product, ProductInstance } from "../Types/Product";
import { formatPriceRange } from "../Util/numberFormatter";
import { createProductVariantInstance } from "./CreateProductVariantInstance";

export const createProductInstanceTransformer = (
  client: Client
): ((data: Product) => ProductInstance) => {
  return (data: Product): ProductInstance => {
    return {
      ...data,
      get displayName() {
        return data.translation?.name || data.name;
      },
      get displayDescription() {
        return data.translation?.description || data.description;
      },
      get displayPrice() {
        if (!data.pricing || !data.pricing.priceRange) {
          return null;
        }

        if (data.pricing.onSale && data.pricing.priceRangeUndiscounted) {
          return `<s>${formatPriceRange(
            data.pricing.priceRangeUndiscounted,
            client.getLanguage()
          )}</s> ${formatPriceRange(
            data.pricing.priceRange,
            client.getLanguage()
          )}`;
        }

        return formatPriceRange(data.pricing.priceRange, client.getLanguage());
      },
      variants: data.variants
        ? data.variants.map((variant) => createProductVariantInstance(variant))
        : [],
    };
  };
};
