import { CATEGORY_FRAGMENT } from "./Category";
import { COLLECTION_FRAGMENT } from "./Collection";
import { PAGE_INFO_FRAGMENT, TAXED_MONEY_FRAGMENT } from "./Common";
import { SELLER_FRAGMENT } from "./Seller";

export const PRODUCT_VARIANT_FRAGMENT = `
  fragment productVariant on ProductVariant {
    id
    name
    sku
    weight {
      unit
      value
    }
    pricing {
      onSale
      discount {
        ...taxedMoney
      }
      discountLocalCurrency {
        ...taxedMoney
      }
      price {
        ...taxedMoney
      }
      priceUndiscounted {
        ...taxedMoney
      }
      priceLocalCurrency {
        ...taxedMoney
      }
    }
    images {
      url
      alt
    }
    translation(languageCode: $language) {
      id
      name
    }
    quantityAvailable
    saasData {
      addToBasketUrl
      addToBasketMethod
      addToBasketParameter
      openProductCardUrl
      openProductCardMethod
      goToProductCardUrl
      goToProductCardMethod
    }
  }
`;

export const SELLING_CONDITIONS_FRAGMENT = `
  fragment sellingConditions on SellingConditionsType {
    id
    fulfillmentTime {
      id
      name
      time
    }
    returnPolicy {
      id
      name
      description
      time
      returnShipment
    }
    warranty {
      id
      name
      time
      warrantyType
    }
  }
`;

export const PRODUCT_FRAGMENT = `
  fragment product on Product {
    id
    name
    description
    slug
    clientUrl
    category {
      ...category
    }
    weight {
      unit
      value
    }
    thumbnail {
      url
      alt
    }
    images {
      url
      alt
    }
    pricing {
      onSale
      discount {
        ...taxedMoney
      }
      discountLocalCurrency {
        ...taxedMoney
      }
      priceRange {
        start {
          ...taxedMoney
        }
        stop {
          ...taxedMoney
        }
      }
      priceRangeUndiscounted {
        start {
          ...taxedMoney
        }
        stop {
          ...taxedMoney
        }
      }
      priceRangeLocalCurrency {
        start {
          ...taxedMoney
        }
        stop {
          ...taxedMoney
        }
      }
    }
    isAvailable
    variants {
      ...productVariant
    }
    collections {
      ...collection
    }
    translation(languageCode: $language) {
      id
      name
      description
    }
    seller {
      ...seller
    }
    sellingConditions {
      ...sellingConditions
    }
  }
`;

export const PRODUCTS = `
  query products(
    $filter: ProductFilterInput
    $order: ProductOrder
    $after: String
    $first: Int
    $language: LanguageCodeEnum!
  ) {
    products(filter: $filter, sortBy: $order, after: $after, first: $first) {
      pageInfo {
        ...pageInfo
      }
      totalCount
      edges {
        cursor
        node {
          ...product
        }
      }
    }
  }
  ${PAGE_INFO_FRAGMENT}
  ${PRODUCT_FRAGMENT}
  ${TAXED_MONEY_FRAGMENT}
  ${PRODUCT_VARIANT_FRAGMENT}
  ${COLLECTION_FRAGMENT}
  ${SELLER_FRAGMENT}
  ${SELLING_CONDITIONS_FRAGMENT}
  ${CATEGORY_FRAGMENT}
`;

export const PRODUCT = `
  query product(
    $id: ID,
    $slug: String,
    $language: LanguageCodeEnum!
  ) {
    product(id: $id, slug: $slug) {
      ...product
    }
  }
  ${PRODUCT_FRAGMENT}
  ${TAXED_MONEY_FRAGMENT}
  ${PRODUCT_VARIANT_FRAGMENT}
  ${COLLECTION_FRAGMENT}
  ${SELLER_FRAGMENT}
  ${SELLING_CONDITIONS_FRAGMENT}
  ${CATEGORY_FRAGMENT}
`;