import { ClientAdapterOptions } from "../Types/ClientAdapter";
import { BaseClientAdapter } from "./BaseClientAdapter";
import {
  ClientOptions as UrqlClientOptions,
  Client as UrqlClient,
} from "@urql/core";

export class UrqlClientAdapter extends BaseClientAdapter {
  private client: UrqlClient;

  constructor(
    createClient: (opts: UrqlClientOptions) => UrqlClient,
    options?: ClientAdapterOptions
  ) {
    super(options);

    this.client = createClient({
      url: this.url,
    });
  }

  async query<T>(
    query: string,
    variables?: Record<string, any>
  ): Promise<T | undefined> {
    const result = await this.client
      .query<T>(query, variables, {
        fetchOptions: this.getFetchOptions(),
      })
      .toPromise();

    if (!result.data || result.error) {
      console.error(result);
    }

    return result.data;
  }

  async mutate<T>(
    query: string,
    variables: Record<string, any>
  ): Promise<T | undefined> {
    const result = await this.client
      .mutation<T>(query, variables, {
        fetchOptions: this.getFetchOptions(),
      })
      .toPromise();

    if (!result.data || result.error) {
      console.error(result);
    }

    return result.data;
  }

  private getFetchOptions(): RequestInit {
    if (this.user && this.user.temporaryToken) {
      return {
        headers: {
          authorization: `JWT ${this.user.temporaryToken}`,
        },
      };
    }

    return {};
  }
}
