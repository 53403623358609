export const COLLECTION_FRAGMENT = `
  fragment collection on Collection {
    id
    name
    description
    slug
    backgroundImage {
      url
      alt
    }
  }
`;
