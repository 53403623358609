import {
  EventBroadcaster,
  EventListener,
  LMEvent,
} from "../Types/EventBroadcaster";

export function createEventBroadcaster<
  E extends string
>(): EventBroadcaster<E> {
  return {
    listeners: {} as Record<E, EventListener[]>,
    addEventListener(key: E, listener: EventListener) {
      if (!this.listeners[key]) {
        this.listeners[key] = [];
      }

      this.listeners[key].push(listener);
    },
    removeEventListener(key: E, listener: EventListener) {
      if (!this.listeners[key] || !this.listeners[key].length) {
        return;
      }

      const index = this.listeners[key].findIndex((f) => f === listener);

      if (index < 0) {
        return;
      }

      this.listeners[key].splice(index, 1);
    },
    _dispatchEvent<T>(key: E, data: T) {
      if (!this.listeners[key] || !this.listeners[key].length) {
        return;
      }

      const event: LMEvent<T> = {
        details: data,
      };

      this.listeners[key].forEach((listener) => {
        try {
          listener(event);
        } catch (e) {
          console.error(e);
        }
      });
    },
  };
}
