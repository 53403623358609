export const TAXED_MONEY_FRAGMENT = `
  fragment taxedMoney on TaxedMoney {
    currency
    gross {
      currency
      amount
    }
    net {
      currency
      amount
    }
    tax {
      currency
      amount
    }
  }
`;

export const PAGE_INFO_FRAGMENT = `
  fragment pageInfo on PageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
`;
