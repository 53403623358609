export const SELLER_FRAGMENT = `
  fragment seller on SellerType {
    id
    name
    displayName
    avatar {
      url
      alt
    }
    country
    email
  }
`;
