import { FetchClientAdapterResponse } from "../Types/FetchClientAdapter";
import { BaseClientAdapter } from "./BaseClientAdapter";
import hash from "../Util/hash";
import { ClientAdapterRequestOptions } from "../Types/ClientAdapter";

export class FetchClientAdapter extends BaseClientAdapter {
  private cache: Record<string | number, any> = {};

  async query<T>(
    query: string,
    variables: Record<string, any>,
    options?: ClientAdapterRequestOptions
  ): Promise<T | undefined> {
    const cacheKey = hash(JSON.stringify(query) + JSON.stringify(variables));
    if (!options?.noCache && this.cache[cacheKey]) {
      return this.cache[cacheKey].data;
    }

    const response = await this.makeRequest<T>(query, variables);

    this.cache[cacheKey] = response;

    return response.data;
  }

  async mutate<T>(
    query: string,
    variables: Record<string, any>,
    options?: ClientAdapterRequestOptions
  ): Promise<T | undefined> {
    const response = await this.makeRequest<T>(query, variables);

    return response.data;
  }

  private async makeRequest<T>(
    query: string,
    variables: Record<string, any>
  ): Promise<FetchClientAdapterResponse<T>> {
    const headers: HeadersInit = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    if (this.user && this.user.temporaryToken) {
      headers.authorization = `JWT ${this.user.temporaryToken}`;
    }

    const response = await fetch(this.url, {
      method: "POST",
      headers,
      body: JSON.stringify({
        query,
        variables,
      }),
    });

    const decodedResponse = await response.json();

    return decodedResponse;
  }
}
